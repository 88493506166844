import { Paginated, Pagination, PaginationQuery } from '@shared/services/common';

export interface LoadState<T> {
  loading: boolean;
  error: boolean;
  errorMessage: string;
  loaded: boolean;
  result?: T;
}

export interface PaginatedLoadState<T> {
  loading: boolean;
  error: boolean;
  errorMessage: string;
  loaded: boolean;
  result?: T;
  query?: any,
  page?: PaginationQuery,
  pagesTotal?: number, 
  total?: number
}

export interface PaginatedSearchState<T, K> {
  loading: boolean;
  error: boolean;
  errorMessage: string;
  loaded: boolean;
  result?: T;
  query?: K,
  page?: PaginationQuery,
  pagesTotal?:number,
  total?: number
}

export const stateInitial: LoadState<any> = {
  loading: false,
  error: false,
  errorMessage: "",
  loaded: false,
  result: null,
};

export const stateLoading: LoadState<any> = {
  loading: true,
  error: false,
  errorMessage: "",
  loaded: false,
};

export const stateError: LoadState<any> = {
  loading: false,
  error: true,
  errorMessage: "",
  loaded: false,
};

export const stateLoaded: LoadState<any> = {
  loading: false,
  error: false,
  errorMessage: "",
  loaded: true,
};
