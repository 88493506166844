export type Pagination = {
  total: number,
  pagesTotal: number,
}

export type Paginated<T> = Pagination & {
  page: number;
  pageSize: number;
  result: T;
  page: number, 
  pageSize: number
}

export type PaginationQuery = Record<'page' | 'pageSize', number>;

export type SearchQuery = Partial<Record<'search', string>>;

// https://github.com/microsoft/TypeScript-DOM-lib-generator/issues/1568 
export function convertToUrlParams(record: Record<string, any>): string {
  return new URLSearchParams(Object.keys(record).reduce((acc, curr) => { if (record[curr] !== undefined) { acc[curr] = record[curr].toString(); } return acc; }, {})).toString();
}

export type DateQuery = {
  date: string,
  dateEnd: string,
  comparator: "later" | "earlier"
}