import axios, { AxiosHeaders, AxiosPromise } from 'axios'
import type { Paginated, Pagination } from './common';

export type UserSearchQuery = {
  page: number,
  pageSize: number,
  email?: string,
  firstName?: string,
  lastName?: string,
  search?: string,
  username?: string,
  exact?: boolean
}

export type KeyCloakUserBase = {
  username: string,
  firstName: string,
  lastName: string,
  email: string,
  enabled: boolean
}

export type KeyCloakUserCreate = KeyCloakUserBase & {
  password: string,
  attributes: {
    language: string[],
    picture?: string
  }  
}

export type KeyCloakUser = KeyCloakUserBase & {
  id: string,
  enabled: boolean,
  emailVerified: boolean,
  requiredActions: string[]
  // NOTE: this is the UNIX epoch in ms, and NOT a datetime string
  createdTimestamp: number,
}

// NOTE: searchUsers does not use the standardized paging query parameter
export function searchUsers(searchQuery: UserSearchQuery): AxiosPromise<Paginated<KeyCloakUser[]>> {
  return axios.post(`/api/portal/users/search`, searchQuery)
}

// NOTE: This is a duplicate of searchUsers, but with less fields and accessible by all users
export function searchUsersInfos(searchQuery: UserSearchQuery){
  return axios.post(`/api/portal/users/search-infos`, searchQuery)
}

export function getUserInfos(users: string[]): AxiosPromise<Paginated<KeyCloakUser[]>> {
  return axios.post('/api/portal/users/infos', { uuids: users });
}

export function getUser(userId: string): AxiosPromise<KeyCloakUser> {
  return axios.get(`/api/portal/users/${userId}`)
}

export type KeyCloakUserPatch = {
  username?: string,
  firstName?: string,
  lastName?: string,
  email?: string,
  enabled?: boolean,
  // NOTE: this is patchable, but does not appear in the search or GET query
  attributes?: {
    language?: string[],
    picture?: string
  }
};

export function patchUser(userId: string, patch: KeyCloakUserPatch): AxiosPromise<KeyCloakUser> {
  return axios.patch(`/api/portal/users/${userId}`, patch);
}

export function deleteUser(userId: string): AxiosPromise<void> {
  return axios.delete(`/api/portal/users/${userId}`)
}

export function postUser(request: KeyCloakUserCreate): AxiosPromise<KeyCloakUser> {
  return axios.post(`/api/portal/users`, request);
}

export type UserRoles = {
  id?: string,
  clientUuid: string,
  // NOTE: this is a human readable id
  clientId?: string,
  title?: string, 
  roles: UserRole[]
}

export type UserRole = {

  id: string,
  name: string,
  description: string

}

export function getRolesForUser(userId: string): AxiosPromise<UserRoles[]> {
  return axios.get(`/api/users/${userId}/roles`)
}

export function addRoles(userId: string, userRoles: { assignments: UserRoles[] }): AxiosPromise<UserRoles[]> {
  return axios.post(`/api/portal/users/assign-roles?${new URLSearchParams({ id: userId })}`, userRoles);
}

export function removeRoles(userId: string, userRoles: { assignments: UserRoles[] }): AxiosPromise<UserRoles[]> {
  return axios.post(`/api/portal/users/remove-roles?${new URLSearchParams({ id: userId })}`, userRoles);
}

export function getRoles() {
  return axios.get(`/api/portal/roles`);
}


// Self user services
