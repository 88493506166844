import { clientId } from './constants';

export const ticketManagementRoutes = [
  {
    path: "/maintenance",
    name: "tmMyTickets",
    props: true,
    component: () => import("@shared/maintenance/views/MyTickets.vue"),
    meta: {
      appClientId: clientId,
      breadcrumbs: [
        {
          label: "myTickets",
          route: { name: "tmMyTickets" }
        }
      ]
    }
  },
  {
    path: "/maintenance-admin",
    name: "tmTicketsAdmin",
    props: true,
    component: () => import("@shared/maintenance/views/TicketOverview.vue"),
    meta: {
      appClientId: clientId,
      breadcrumbs: [
        {
          label: "ticketAdmin",
          route: { name: "tmTicketsAdmin" }
        }
      ]
    }
  },
  {
    path: "/maintenance/create",
    name: "tmCreateTicket",
    props: true,
    component: () => import("@shared/maintenance/views/CreateMaintenanceTicket.vue"),
    meta: {
      appClientId: clientId,
      breadcrumbs: [
        {
          label: "myTickets",
          route: { name: "tmMyTickets" }
        },
        {
          label: "createTicket",
          route: { name: "tmCreateTicket" }
        },
      ]
    }
  },
  {
    path: "/maintenance/ticket/:id",
    name: "tmTicketDetail",
    props: true,
    component: () => import("@shared/maintenance/views/TicketDetail.vue"),
    meta: {
      appClientId: clientId,
      breadcrumbs: [
        {
          label: "myTickets",
          route: { name: "tmMyTickets" }
        }
      ]
    }
  },
  {
    path: "/maintenance/access",
    name: "tmAccess",
    props: true,
    component: () => import("@shared/maintenance/views/MaintenanceAccess.vue"),
    meta: {
      appClientId: clientId,
      breadcrumbs: [
        {
          label: "accessRights",
          route: { name: "tmAccess" }
        }
      ]
    }
  },

  {
    path: "/maintenance/plans",
    name: "tmMaintenancePlans",
    component: () => import("@shared/maintenance/views/MaintenancePlanList.vue"),
    meta: {
      appClientId: clientId,
      breadcrumbs: [
        {
          label: "maintenancePlans",
          route: { name: "tmMaintenancePlans" }
        }
      ]
    }
  },

  {
    path: "/maintenance/calendar",
    name: "tmMaintenanceCalendar",
    component: () => import("@shared/maintenance/views/TicketCalendar.vue"),
    meta: {
      appClientId: clientId,
      breadcrumbs: [
        {
          label: "calendar",
          route: { name: "tmMaintenanceCalendar" }
        }
      ]
    }
  },

  {
    path: "/maintenance/plans/edit/:id?",
    name: "tmMaintenancePlansEdit",
    props: true,
    component: () => import("@shared/maintenance/views/MaintenancePlanCreateUpdate.vue"),
    meta: {
      appClientId: clientId,
      breadcrumbs: [
        {
          label: "maintenancePlans",
          route: { name: "tmMaintenancePlans" }
        }
      ]
    }
  },


  {
    path: "/maintenance/sop",
    name: "tmSop",
    props: true,
    component: () => import("@shared/maintenance/views/SopManagement.vue"),
    meta: {
      appClientId: clientId,
      breadcrumbs: [
        {
          label: "sopManagement",
          route: { name: "tmSop" }
        }
      ]
    }
  },
  {
    path: "/maintenance/sop/:id",
    name: "tmSopDetail",
    props: true,
    component: () => import("@shared/maintenance/views/SopVersions.vue"),
    meta: {
      appClientId: clientId,
      breadcrumbs: [
        {
          label: "sopManagement",
          route: { name: "tmSop" }
        }
      ]
    }
  },
  {
    path: "/maintenance/sop/:id/edit",
    name: "tmSopEdit",
    props: true,
    component: () => import("@shared/maintenance/views/SopEdit.vue"),
    meta: {
      appClientId: clientId,
      breadcrumbs: [
        {
          label: "sopManagement",
          route: { name: "tmSop" }
        }
      ]
    }
  }


] 