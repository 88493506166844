import { useAppStore } from '@shared/stores/apps';
import { onMounted } from 'vue';
// import duration from 'dayjs/plugin/duration';
import dayjs from 'dayjs';


export function initTicketManagementApp() {
  const appStore = useAppStore();

  // dayjs.extend(duration);

  onMounted(() => {
    appStore.setApplinks([
      {
        uriExpression: 'twin-edit.tm.ticket.*',
        routeName: "tmTicketDetail"
      },
    ])

  })
}