import { createRouter, createWebHistory } from 'vue-router'
import { loginGuard } from "@shared/router/loginGuard";
import { warehouseRoutes } from '../../../shared/src/warehouse/routes';
import { assetManagementRoutes } from '@shared/assetManagement/routes';
import { twinModellingRoutes } from '@shared/twinModelling/routes';
import { userManagementRoutes } from '@shared/components/userManagement/router';
import { groupManagementRoutes } from '@shared/components/groupManagement/router';
import { ticketManagementRoutes }  from '@shared/maintenance/routes';
import { baseRoutes } from '@shared/baseRoutes';
import { clearBreadCrumbs } from '@shared/util/breadCrumbs';
import { qmdRoutes } from '@shared/qualityManagementDocuments/routes';
import { itAssetManagementRoutes } from '@shared/itAssetManagement/routes';



const routes =  [
  ...baseRoutes,
  ...warehouseRoutes,
  ...assetManagementRoutes,
  ...twinModellingRoutes,
  ...userManagementRoutes,
  ...ticketManagementRoutes,
  ...qmdRoutes,
  ...itAssetManagementRoutes,
  ...groupManagementRoutes,
  {
    path: '/',
    name: 'home',
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
  },

  {
    path: "/apps-admin/",
    name: "appsAdmin",
    component: () => import("@shared/components/apps/AppsSettings.vue"),
    meta:
    {
      appClientId: "smx-portal-mgmt",
      breadcrumbs: [{
        label: 'appsAdmin',
        route: { name: "appsAdmin" }
      }]
    }
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

router.beforeEach(loginGuard);
router.beforeEach(clearBreadCrumbs);

export default router
