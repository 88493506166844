import { publicPages } from '@shared/router/loginGuard';
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';

export default  function useMenu() {

  const isPublicPage = ref(true);
  
  const router = useRouter();
  async function checkRoute() {
    await router.isReady();
    isPublicPage.value = publicPages.includes(router.currentRoute.value.fullPath)
  }
  watch(router.currentRoute, checkRoute)

  return {
    isPublicPage
  }

}