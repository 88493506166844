export const userManagementRoutes = [
  {
    path: "/user-management",
    name: "userManagement",
    component: () => import("@shared/components/userManagement/UserManagement.vue"),
    meta:
    {
      appClientId: "smx-user-mgmt",
      breadcrumbs: [{
        label: 'userManagement',
        route: { name: "userManagement" }
      }]
    }
  },
  {
    path: "/user-management/:id",
    name: "userManagementDetail",
    props: true,
    component: () => import("@shared/components/userManagement/UserDetail.vue"),
    meta:
    {
      appClientId: "smx-user-mgmt",
      breadcrumbs: [{
        label: 'userManagement',
        route: { name: "userManagement" }
      }]
    }
  },
  {
    path: "/user-management/create",
    name: "userManagementCreate",
    component: () => import("@shared/components/userManagement/UserDetail.vue"),
    meta:
    {
      appClientId: "smx-user-mgmt",
      breadcrumbs: [
        {
        label: 'userManagement',
        route: { name: "userManagement" }
      },
        {
        label: 'createUser',
        route: { name: "userManagementCreate" }
      }
    ]
    }
  }
]