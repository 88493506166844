<template lang="pug">
el-config-provider(:locale="elementPlusLanguage")
  SmxBaseLayout
    template(#navbar)
      Navbar(          
        v-if="!isPublicPage"
        :post-logout="logoutCallback"
        title=""
        logo-path="/img/logo.png"
        logo-path-small="/img/logo-small.png"
        )
    template(#menu)
      SmxMenu(v-if="!isPublicPage")        
    .app-content(
      class="md:p-10 max-md:p-5")
      RouterView(:key="route.path")
</template>

<script setup lang="ts">
import { computed, onMounted, watch, ref } from 'vue';
import { RouterLink, RouterView, useRoute, useRouter } from "vue-router";
import Navbar from "@shared/components/Header.vue";
import router from "@schulz/router";
import { useI18n } from 'vue-i18n';
import { publicPages } from '@shared/router/loginGuard';
import { useAuthStore } from '@shared/stores/auth';
import { useAppStore } from '@shared/stores/apps';
import de from 'element-plus/dist/locale/de.mjs';
import en from 'element-plus/dist/locale/de.mjs';
import SmxMenu from '@shared/components/SmxMenu.vue';
import { useMenuStore } from '@shared/stores/menu';
import SmxBaseLayout from '@shared/views/SmxBaseLayout.vue';
import { useMyAccountStore } from '@shared/stores/myAccount';
import { whArticlesSingle, whArticlesMultiple } from '@shared/warehouse/constants';
import { initAssetManagementApp } from '@shared/assetManagement/hooks/initAssetManagement';
import { initWarehouseApp } from '@shared/warehouse/hooks/warehouseInit';
import { initTicketManagementApp } from '@shared/maintenance/hooks/initTicketManagement';
import { useKeyCloakAuthentication } from '@shared/hooks/authentication';
import useMenu from '@shared/hooks/menu';
import { initItAssetManagementApp } from '@shared/itAssetManagement/hooks/initItAssetManagement';


const { t } = useI18n();

const myAccountStore = useMyAccountStore();
useKeyCloakAuthentication();
const {isPublicPage} = useMenu();

const elementPlusLanguage = computed(() => {
  switch (myAccountStore.language) {
    case "en":
      return en;
    default:
      return de;
  }
})

const components = {
  Navbar,
};

const appStore = useAppStore();

const menuStore = useMenuStore();

const logoutCallback = () => {
  router.push({ name: "login" });
};


initWarehouseApp();
initAssetManagementApp();
initItAssetManagementApp();
initTicketManagementApp();

onMounted(() => {
  appStore.loadAppsOverview({}, false);
})

const route = useRoute();

</script>

<style>
body {
  max-width: 100vw;
  overflow-x: hidden;
}

.app-body {
  display: flex;
}

.app-content {
  width: 100%;
}
</style>
