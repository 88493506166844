export const ticketManagementPrefix = "tm.ticket.maintenance.*";

export const clientId = "smx-maintenance"

export const materialConsumptionId = "materialConsumption";

export const statuses = {
  draft: 'draft',
  new: 'new',
  planned: 'planned',
  inProcess: 'in process',
  completed: 'completed',
  rejected: 'rejected',
  confirmed: 'confirmed'
}


export const ticketProps = {
  title: "title",
  assignee: "assignee",
  requester: "requester",
  status: "status",
  dueDate: "dueDate",
  startDate: "startDate",
  priority: "priority",
  createdBy: "createdBy",
  workCarriedOut: "workCarriedOut",
  asset: "asset",
  area: "area",
  maintenanceRequestType: "maintenanceRequestType",
  ticketNumber: "ticketNumber"
}