import { type LoadState, stateInitial, stateLoading, stateError, stateLoaded } from '@shared/models/state';
import { type MyAccountData, getUserSelf } from '@shared/services/myAccount';
import { patchUser, type KeyCloakUserPatch } from '@shared/services/userManagementKeyCloak';
import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';

export const useMyAccountStore = defineStore("myAccount", () => {

  const language = ref("de")

  const i18n = useI18n();
  
  function setLanguage(langCode: string) {
    language.value = langCode;
    i18n.locale.value = langCode;
  }

  const userDataLoadingState = ref<LoadState<MyAccountData>>({...stateInitial});

  const userData = computed<MyAccountData>(() => userDataLoadingState.value.result);

  async function initUserData(reload=true){
    if(!reload && userDataLoadingState.value.loaded) {
      return;
    }

    userDataLoadingState.value = { ...stateLoading };
    const response = await getUserSelf().catch(e => {
      userDataLoadingState.value = { ...stateError };
      throw e;
    });
    userDataLoadingState.value = { ...stateLoaded, result: response.data };
 
  }

  async function updateUserData(data: KeyCloakUserPatch) {
    if(userData.value?.id === undefined) {
      throw Error('MyAccountStore: Trying to update user, but data is not loaded');
    }
    await patchUser(userData.value.id, data);
    initUserData(true);
  }

  return {
    setLanguage, 
    language,
    initUserData,
    userDataLoadingState,
    userData, 
    updateUserData
  }
})